import "./Carousals.css";
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-grid-carousel';
import { useState, useEffect } from 'react';
import { FaDownload } from 'react-icons/fa';
import { backend_url } from '../constants';

const fetchData = async () => {
  try {
    const urlParts = window.location.pathname.split('/');
    const lastPart = urlParts[urlParts.length - 1];

    // Constructing the fetch URL
    const fetchUrl = `${backend_url}/model_report?run_id=${lastPart}`; // Prod server
    
    const response = await fetch(fetchUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': 'true',
      },
    });
    const data = await response.json();
    
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};

export const DisplayModelReport = ({ data }) => {
  return (
    <div>
      <Carousel cols={1} rows={1} loop>
        {data.models.map((url, index) => (
          <Carousel.Item key={index}>
            <br />
                <div className="carousel-item-container">
                  <img className="carousel-item-image" src={url} />
                </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export const DisplayProgressBar = ({ run_id }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [status, setStatus] = useState('');

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        console.log(`${backend_url}/status?run_id=${run_id}`);
        let fetchUrl = `${backend_url}/status?run_id=${run_id}`;
        const response = await fetch(fetchUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'ngrok-skip-browser-warning': 'true',
          },
        });
        const data = await response.json();
        console.log(data);
        if (response.status === 200 && data.status === 200) {
          setShowAlert(true);
          setStatus(data);
        }
      } catch (error) {
        console.error('Failed to fetch status:', error);
      }
    };

    fetchStatus();
  }, []);

  const handleClose = () => {
    setShowAlert(false);
  };
  
  let content = '';
  if (status.status === 200) {
    if (status.ETA === null) {
      content = (
        <div>
          <span className="font-semibold">Request in queue!</span> Jobs ahead: {status.jobs_ahead}
        </div>
      );
    }
    else if (status.ETA > 0.0) {
      content = (
        <div>
          <span className="font-semibold">Crunching numbers!</span> ETA in minutes: {status.ETA}
        </div>
      );
    }
    else {
      content = (
        <div>
          <span className="font-semibold mr-2">Run completed!</span>
          <a href="#" onClick={() => handleDownload(run_id)} className="inline-flex items-center underline">
            Click to download results (may take few minutes to start downloading...)
          </a>
        </div>
      );
    }
  }

  return (
    <div className="mx-auto w-4/5">
      {showAlert && (
        <div id="alert-4" className="flex items-center p-4 mb-4 text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300" role="alert">
          <svg className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
          </svg>
          <span className="sr-only">Info</span>

          <div className="ms-3 text-sm font-medium">
            {content}
          </div>

          <button type="button" onClick={handleClose} className="ms-auto -mx-1.5 -my-1.5 bg-yellow-50 text-yellow-500 rounded-lg focus:ring-2 focus:ring-yellow-400 p-1.5 hover:bg-yellow-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-yellow-300 dark:hover:bg-gray-700" aria-label="Close">
            <span className="sr-only">Close</span>
            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};   

export const DisplaySyntheticData = ({ data }) => {
  return (
    <div className="mx-auto w-4/5">
      <hr className="border-3 border-gray-300 mt-10" />
      
      <div className="flex items-center justify-center">
        <h2 className="text-2xl mt-4 mb-4 mr-2">Labeled Synthetic Data</h2>
      </div>
      {data.images.length > 0 ? (
        
      <Carousel cols={5} rows={1} loop>
        {data.images.map((url, index) => (
          <Carousel.Item key={index}>
            <div className="carousel-item-container">
              <img className="carousel-item-image" src={url} />
              <div className="margin-between-images"></div>
              <img className="carousel-item-image" src={url.replace("/images/", "/masks/")} />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      ) : (
        <p className="mt-4 mb-4">No synthetic data available at this time. Please check back later.</p>
      )}
    </div>
  );
}

export const DisplayModelRealData = ({ data }) => {
  return (
    <div className="mx-auto w-4/5 grid grid-cols-5 gap-1">
      <div className="col-span-5"> {/* Full-width column, occupies all 5 columns */}
        <hr className="border-3 border-gray-300 mb-4 mt-4" /> {/* Horizontal line */}
      </div>
      <div className="col-span-2">
        <h2 className="text-2xl">Real Data</h2>
        <br></br>
        <Carousel cols={3} rows={1} loop>
        {data.real.images.map((url, index) => (
          <Carousel.Item key={index}>
            <div className="carousel-item-container">
              <img className="carousel-item-image" src={url} />
              <div className="margin-between-images"></div>
              <img className="carousel-item-image" src={url.replace("/images/", "/masks/")} />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      </div>
      <div className="col-span-3">
        <h2 className="text-2xl">Model Performance</h2>
        <br></br>
        {data.synthetic.models && data.synthetic.models.length > 0 ? (
            <Carousel cols={2} rows={1} loop>
              {data.synthetic.models
                .filter(url => url.endsWith('.png')) // Filter URLs ending with '.png'
                .map((url, index) => (
                  <Carousel.Item key={index}>
                    <div className="carousel-item-container">
                      <img className="carousel-item-image" src={url} />
                    </div>
                  </Carousel.Item>
                ))}
            </Carousel>
          ) : (
            <p className="mt-4">No model data available at this time. Please check back later.</p>
          )}
      </div>
      <br></br>
      <br></br>
    </div>
  );
}

const Footer = () => {
  return (
    <footer className="bg-gray-800 py-4 mt-auto">
      <div className="container mx-auto text-center text-white">
        <p>
          Contact Support: {' '}
          <a href="mailto:support@advexai.com?subject=Advex AI Bug" className="underline">support@advexai.com</a>
        </p>
      </div>
    </footer>
  );
};


export const handleDownload = async (run_id) => {
  try {
    const fetchUrl = `${backend_url}/download_results?run_id=${run_id}`;
    const response = await fetch(fetchUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'ngrok-skip-browser-warning': 'true',
      },
    });

    // download the zip file returned from the server
    const blob = await response.blob();
    // check if response is successful
    if (!response.ok) {
      alert('Failed to download data. Please try again later.');
      throw new Error('Failed to download data');
    }
    
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = `advex_results_${run_id}.zip`;
    document.body.appendChild(link);
    link.click();
    link.remove();
    
  } catch (error) {
    console.error('Failed to download data:', error);
    alert('Failed to download data. Please try again later.');
    
  }
};

export const MetricsPage = () => {
  const [imageData, setImageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchImageData = async () => {
      const data = await fetchData();
      if (data) {
        setImageData(data);
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
      }
    };

    fetchImageData();

    const timeoutId = setTimeout(() => {
      setLoading(false);
      //setError(true);
    }, 5000); // 5 seconds timeout for loading

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex-grow">
        <div className="App">
          <div className="mb-8 mt-8">
            <h1 className="text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">Training Dashboard</h1>
            <h2 className="text-lg mt-4 text-blue-600">
              <a href="/upload" target="_blank" className="hover:text-blue-600">Try Advex on your dataset</a>
            </h2>
          </div>

          {loading ? (
            <div className="flex justify-center items-center">
              <div className="loader"></div>
            </div>
          ) : error ? (
            <div className="text-2xl text-center mt-4">
              <br></br>
              <br></br>
              Error fetching data. Make sure you're using the right URL. 
              <br></br>
              <br></br>
              If the error persists, contact support.
            </div>
          ) : (

            // add the above line to display the progress bar
            // should match react tags with the below code
            
            imageData.links && imageData.links.real.images.length > 0 ? (
              <>
                {/* get run_id by taking the last path of the page */}
                <DisplayProgressBar run_id={window.location.pathname.split('/').pop()} />
                {imageData && imageData.links && imageData.links.synthetic && <DisplaySyntheticData data={imageData.links.synthetic} />}
                {imageData && imageData.links && imageData.links.real && <DisplayModelRealData data={imageData.links} />}
              </>
            ) : (
              <div className="text-2xl text-center mt-4">
                No data available at this time. Please check back later.
              </div>
            )
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

