// List of constants used in the application
// ----------------------------------------------------------------
export const backend_url = 'https://advex-client-backend.ngrok.app';
//export const backend_url = 'http://127.0.0.1:5000';
export const example_routes = [
    "4819869023bd43fe9781860384a97224", 
    "4819869023bd43fe9781860384a97223", 
    "4819869023bd43fe9781860384a97222", 
    "81cdc9aed13b4b91907d99368dce7b6f", 
    "1b6dfe44f5184c828b42a44824562b55", 
    "4819869023bd43fe9781860384a97220", 
    "7594bd7d-97f3-4077-a106-a5eb8ee6bc15", 
    "fd6c3428f7a147069d4787191ff0ef55", 
    "4819869023bd43fe9781860384a97221",
    "0aaa9411cd144cd1be28c5cc52cddaf4", // Moog delivery
];
export const approved_organizations = ["advexai.com"];
// export const backend_url = 'http://207.188.6.141:5000';